import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-status-confirmation',
  standalone: true,
  imports: [CommonModule,ConfirmDialogModule, NgSelectModule ,FormsModule ,ReactiveFormsModule],
  providers: [ConfirmationService],
  templateUrl: './status-confirmation.component.html',
  styleUrl: './status-confirmation.component.scss'
})
export class StatusConfirmationComponent {

  @Input() status:string = "approved";
  @Output() approve = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  @ViewChild('cd') cd: any;

  header!: string;
  message!: string;
  reasons = ['documents not submitted','invalid bank details'];
  reason:any;
  reasonStatus :boolean = false;

  constructor(private confirmationService: ConfirmationService) {}

  show(header: string, message: string) {
    this.header = header;
    this.message = message;

    this.confirmationService.confirm({
      header: this.header,
      message: this.message,
      accept: () => this.onApprove(),
      reject: () => this.onCancel()
    });
  }

  onApprove() {
    console.log("this status",this.status);
    console.log("this status",this.reason);
    console.log("this reason",this.status == 'rejected' && this.reason == null);
    
    if(this.status == 'rejected') {

      if(this.reason){
        this.reasonStatus = false;
        this.approve.emit(this.reason);
        this.cd.hide();
      }
      else {
        this.reasonStatus = true;
      }
    
    }

    if(this.status == 'approved' ) {
      this.approve.emit();
      this.cd.hide();
    }
    // else {
    //   this.approve.emit();
    //   this.cd.hide();
    // }
    // this.approve.emit();
    // this.cd.hide();
  }

  onCancel() {
    this.reasonStatus = false;
    this.cancel.emit();
    this.cd.hide();
  }

  getStatusColor(): any {

    console.log("thisssssssss triggers",this.status);
    
    switch (this.status) {
      case 'approved':
        return 'custom-button-approve';
      case 'rejected':
        return 'custom-button-reject';
      default:
        return 'custom-button-approve'; // Default color
    }
  }
}
