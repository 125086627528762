<!-- <app-breadcrumbs
  title="Create New Quotation"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs> -->

<div class="row">
  <div class="col-lg-12">
    <span class="d-flex">
      <i
        (click)="onClickCancel()"
        class="pi pi-arrow-left"
        style="
          font-size: 1.4rem;
          stroke-width: 0.4;
          font-size: 1.4rem;
          margin: 5px 15px 0px 1px;
          cursor: pointer;
          color: #878a99;
        "
      ></i>
      <h2 class="mb-4 text-primary">Create New Quotation</h2>
    </span>
    <div class="card">
      <form
        [formGroup]="quotationAddForm"
        (ngSubmit)="addNewQuotation()"
        enctype="multipart/form-data"
      >
        <div class="card-body">
          <div class="live-preview">
            <div class="cus-form-sub-title fs-5 mb-4">Personal Details</div>
            <div class="row gy-4">
              <div class="col-xxl-4 col-md-4">
                <div>
                  <label for="basiInput" class="form-label"
                    >NIC <span class="required-star">*</span></label
                  >
                  <input
                    type="text"
                    appTextInput="nic"
                    class="form-control"
                    formControlName="nic"
                    id="basiInput"
                    placeholder="NIC"
                  />
                  <p
                    #nicError
                    *ngIf="
                      isSubmitted &&
                      quotationAddForm.get('nic')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    NIC is required
                  </p>
                  <span *ngIf="hasErrorFormCheck('nic')" #nicError>
                    <app-error [errors]="filterErrorsByKey('nic')"></app-error>
                  </span>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-4 col-md-4">
                <label for="choices-single-default" class="form-label"
                  >Title <span class="required-star">*</span></label
                >
                <ng-select
                  class="my-dropdown"
                  [items]="titles"
                  formControlName="title"
                  bindLabel="name"
                  placeholder="Title"
                  [(ngModel)]="title"
                >
                  <ng-template ng-optgroup-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <p
                  #titleError
                  *ngIf="
                    isSubmitted &&
                    quotationAddForm.get('title')?.hasError('required')
                  "
                  class="text-danger m-0"
                >
                  Title is required
                </p>
                <!-- <span *ngIf="hasErrorFormCheck('title_id')">
                  <app-error
                    [errors]="filterErrorsByKey('title_id')"
                  ></app-error>
                </span> -->
                <span *ngIf="hasErrorFormCheck('title_id')" #titleError>
                  <app-error
                    [errors]="filterErrorsByKey('title_id')"
                  ></app-error>
                </span>
              </div>

              <div class="col-xxl-4 col-md-4">
                <div>
                  <label class="form-label"
                    >Name With Initials
                    <span class="required-star">*</span></label
                  >
                  <input
                    type="text"
                    appTextInput="text"
                    class="form-control"
                    formControlName="nameWithInitials"
                    placeholder="Name With Initials"
                  />
                  <p
                    #nameWithInitialError
                    *ngIf="
                      isSubmitted &&
                      quotationAddForm
                        .get('nameWithInitials')
                        ?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Name with initials is required
                  </p>
                  <p
                    *ngIf="
                      quotationAddForm?.get('nameWithInitials')?.value
                        ?.length >= 50
                    "
                    class="text-danger m-0"
                  >
                    Maximum character count is 50
                  </p>
                  <span
                    *ngIf="hasErrorFormCheck('name_with_initials')"
                    #nameWithInitialError
                  >
                    <app-error
                      [errors]="filterErrorsByKey('name_with_initials')"
                    ></app-error>
                  </span>
                </div>
              </div>

              <div class="col-xxl-4 col-md-4">
                <div>
                  <label class="form-label"
                    >First Name <span class="required-star">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    appTextInput="text"
                    formControlName="firstName"
                    placeholder="First Name"
                  />
                  <p
                    #firstNameError
                    *ngIf="
                      isSubmitted &&
                      quotationAddForm.get('firstName')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    First name is required
                  </p>
                  <p
                    *ngIf="
                      quotationAddForm?.get('firstName')?.value?.length >= 50
                    "
                    class="text-danger m-0"
                  >
                    Maximum character count is 50
                  </p>
                  <span *ngIf="hasErrorFormCheck('first_name')" #firstNameError>
                    <app-error
                      [errors]="filterErrorsByKey('first_name')"
                    ></app-error>
                  </span>
                </div>
              </div>

              <div class="col-xxl-4 col-md-4">
                <div>
                  <label class="form-label">Middle Name</label>
                  <input
                    type="text"
                    class="form-control"
                    appTextInput="text"
                    formControlName="middleName"
                    placeholder="Middle Name"
                  />
                  <p
                    *ngIf="
                      quotationAddForm?.get('middleName')?.value?.length >= 50
                    "
                    class="text-danger m-0"
                  >
                    Maximum character count is 50
                  </p>
                  <p
                    middleNameError
                    *ngIf="
                      isSubmitted &&
                      quotationAddForm.get('middleName')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Middle name is required
                  </p>
                  <span
                    *ngIf="hasErrorFormCheck('middle_name')"
                    #middleNameError
                  >
                    <app-error
                      [errors]="filterErrorsByKey('middle_name')"
                    ></app-error>
                  </span>
                </div>
              </div>

              <div class="col-xxl-4 col-md-4">
                <div>
                  <label class="form-label">Last Name / Surname</label>
                  <input
                    type="text"
                    class="form-control"
                    appTextInput="text"
                    formControlName="lastName"
                    placeholder="Last Name / Surname"
                    maxlength="50"
                  />
                </div>
                <p
                  *ngIf="quotationAddForm?.get('lastName')?.value?.length >= 50"
                  class="text-danger m-0"
                >
                  Maximum character count is 50
                </p>
                <span *ngIf="hasErrorFormCheck('last_name')" #lastNameError>
                  <app-error
                    [errors]="filterErrorsByKey('last_name')"
                  ></app-error>
                </span>
              </div>

              <div class="col-xxl-4 col-md-4">
                <div>
                  <label class="form-label"
                    >Mobile Number <span class="required-star">*</span></label
                  >
                  <input
                    type="tel"
                    appTextInput="mobile"
                    class="form-control"
                    formControlName="mobileNumber"
                    placeholder="Mobile Number"
                    appMobileNumberMask
                  />
                  <p
                    #mobileError
                    *ngIf="
                      isSubmitted &&
                      quotationAddForm.get('mobileNumber')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Mobile number is required
                  </p>
                  <span *ngIf="hasErrorFormCheck('mobile_number')" #mobileError>
                    <app-error
                      [errors]="filterErrorsByKey('mobile_number')"
                    ></app-error>
                  </span>
                </div>
              </div>

              <div class="col-xxl-4 col-md-4">
                <div>
                  <label class="form-label">Email</label>
                  <input
                    type="email"
                    appTextInput="email"
                    class="form-control"
                    formControlName="email"
                    placeholder="Email"
                    validateEmail
                  />
                  <p
                    *ngIf="
                      quotationAddForm.get('email')?.hasError('email') &&
                      quotationAddForm.get('email')?.touched
                    "
                    class="text-danger m-0"
                  >
                    Please enter a valid email.
                  </p>
                  <span *ngIf="hasErrorFormCheck('email')" #emailError>
                    <app-error
                      [errors]="filterErrorsByKey('email')"
                    ></app-error>
                  </span>
                </div>
              </div>

              <div class="col-xxl-4 col-md-4">
                <div>
                  <label class="form-label">Landline Number </label>
                  <input
                    appMobileNumberMask
                    type="tel"
                    appTextInput="mobile"
                    class="form-control"
                    formControlName="landLineNumber"
                    placeholder="Landline Number"
                  />
                  <p
                    #landLineNumber
                    *ngIf="
                      isSubmitted &&
                      quotationAddForm
                        .get('landLineNumber')
                        ?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Mobile number is required
                  </p>
                  <span
                    *ngIf="hasErrorFormCheck('mobile_number')"
                    #landLineNumber
                  >
                    <app-error
                      [errors]="filterErrorsByKey('mobile_number')"
                    ></app-error>
                  </span>
                </div>
              </div>
              <div class="col-xxl-8 col-md-8">
                <div>
                  <label class="form-label">Address</label>
                  <textarea
                    class="form-control"
                    formControlName="address"
                    rows="3"
                    maxlength="100"
                    placeholder="Address"
                  ></textarea>
                  <p
                    *ngIf="quotationAddForm?.get('address')?.hasError('max')"
                    class="text-danger m-0"
                  >
                    Address cannot exceed 100 characters
                  </p>
                  <span *ngIf="hasErrorFormCheck('address')" #addressError>
                    <app-error
                      [errors]="filterErrorsByKey('address')"
                    ></app-error>
                  </span>
                </div>
              </div>
              <div class="col-xxl-12 col-md-12">
                <div class="cus-form-sub-title fs-5 mt-2">Plan Details</div>
              </div>

              <div formArrayName="planAmounts">
                <div
                  *ngFor="let planAmount of getControls(); let i = index"
                  class="col-xxl-12 col-md-12"
                >
                  <div class="row mt-2" [formGroupName]="i">
                    <div class="col-xxl-4 col-md-4">
                      <label for="plan-{{ i }}" class="form-label"
                        >Plans <span class="required-star">*</span></label
                      >
                      <ng-select
                        class="my-dropdown planSelectWrapper"
                        [items]="plansList"
                        placeholder="Select a Plan"
                        formControlName="plan"
                        id="plan-{{ i }}"
                        bindLabel="name"
                      >
                        <ng-template ng-optgroup-tmp let-item="item">
                          {{ item.name }}
                        </ng-template>
                      </ng-select>
                      <p
                        *ngIf="
                          isSubmitted &&
                          planAmount?.get('plan')?.hasError('required')
                        "
                        class="text-danger"
                      >
                        Plan is required
                      </p>
                    </div>
                    <!--end col-->
                    <div class="col-xxl-4 col-md-4">
                      <div>
                        <label for="amount-{{ i }}" class="form-label"
                          >Amount <span class="required-star">*</span></label
                        >
                        <input
                          type="number"
                          class="form-control"
                          formControlName="amount"
                          id="amount-{{ i }}"
                          placeholder="Amount"
                        />
                        <p
                          *ngIf="
                            isSubmitted &&
                            planAmount?.get('amount')?.hasError('required')
                          "
                          class="text-danger m-0"
                        >
                          Amount is required
                        </p>
                        <p
                          *ngIf="planAmount?.get('amount')?.hasError('max')"
                          class="text-danger m-0"
                        >
                          Maximum amount is 999999
                        </p>
                        <span
                          *ngIf="hasErrorFormCheck('plans.' + i + '.amount')"
                        >
                          <app-error
                            [errors]="
                              filterErrorsByKey('plans.' + i + '.amount')
                            "
                          ></app-error>
                        </span>
                      </div>
                    </div>

                    <!--end col-->
                    <div class="col-xxl-4 col-md-4">
                      <div>
                        <label class="form-label">&nbsp;</label><br />
                        <button
                          class="btn btn-danger btn-sm me-2 custom-remove-btn"
                          type="button"
                          *ngIf="getControls().length > 1 && i > 0"
                          (click)="onClickPlanRemove(i)"
                        >
                          Remove
                        </button>

                        <!--  <button
                          class="btn btn-primary btn-sm custom-add-button add_botton_aling"
                          type="button"
                          (click)="onClickPlanAdd()"
                          *ngIf="i === 0"
                        >
                          <i-feather
                            name="plus-circle"
                            class="text-white icon-dual icon_subPlus"
                          ></i-feather>
                          &nbsp; Add
                        </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xxl-12 col-md-12 addButtonClickAling">
                <button
                  class="btn btn-primary btn-sm custom-add-button add_botton_aling"
                  type="button"
                  (click)="onClickPlanAdd()"
                >
                  <i-feather
                    name="plus-circle"
                    class="text-white icon-dual icon_subPlus"
                  ></i-feather>
                  &nbsp; Add
                </button>
              </div>

              <!--end col-->
            </div>
          </div>
          <!--end row-->
        </div>

        <div class="card-footer custom-tr text-end">
          <button
            type="button"
            (click)="onClickCancel()"
            class="btn btn-light waves-effect waves-light me-2"
          >
            Cancel
          </button>
          <button
            [disabled]="submittedData"
            type="submit"
            class="btn btn-primary waves-effect waves-light"
          >
            <i *ngIf="submittedData" class="mdi mdi-spin mdi-dots-circle"></i>
            {{ submittedData == true ? "Please Wait..." : "Create & Download" }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <!--end col-->
</div>
<!--end row-->
