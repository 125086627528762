<!-- <app-breadcrumbs
  title=""
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>  -->

<div class="row">
  <div class="col-lg-12">
    <h2 class="mb-4 text-primary">
      <i-feather name="pocket" class="text-primary icon-dual"></i-feather>
      Designations
    </h2>

    <div class="card">
      <div class="card-header custom-tr text-end">
        <button
          type="button"
          class="btn btn-primary waves-effect waves-light px-5"
          data-bs-toggle="modal"
          data-bs-target="#designationContentModal"
          data-bs-whatever="Mary"
          (click)="openDesignationModal(designationAddModal)"
        >
          <i-feather
            name="plus-circle"
            class="text-white icon-dual"
          ></i-feather>
          Add New Designation
        </button>
      </div>
      <!-- end card header -->

      <div class="card-body flex justify-content-center overflow-x-auto">
        <div class="org-chart-container custom-overflow">
          <div #zoom class="zoom-container">
            <p-organizationChart
              [value]="designationData"
              selectionMode="single"
              [(selection)]="selectedNodes"
              [collapsible]="true"
              class="tree_area"
            >
              <ng-template let-node pTemplate="person">
                <div
                  style="padding: 45px 20px"
                  class="text-center"
                  (contextmenu)="onNodeRightClickMenu($event, node, cm)"
                  [ngClass]="node.data.styleClass"
                >
                  <!-- <img 
                                [src]="node.data.image" 
                                class="mb-3 custom-w-3rem h-3rem" /> -->
                  <div class="font-bold hirachy-content">
                    {{ node.data.name }}
                  </div>

                  <!-- <div>
                                {{ node.data.title }}
                            </div> -->
                </div>
              </ng-template>
            </p-organizationChart>
          </div>

          <span class="d-flex justify-content-center align-items-center w-100">
            <!-- <img
              *ngIf="!designationList"
              src="../../../../assets/images/cgl/other/noData.PNG"
              alt="nodata"
            /> -->
          </span>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<ng-template #designationAddModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="designationContentModal">
      Add New Designation
    </h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click'); onModalClose()"
    ></button>
  </div>
  <form (ngSubmit)="createNewDesignation()" [formGroup]="designationAddForm">
    <div class="modal-body">
      <div class="mb-3">
        <label for="designationName" class="col-form-label"
          >Designation Name<span class="required-star">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="designationName"
          placeholder="Designation Name"
          id="designationName"
        />
        <div
          *ngIf="
            isSubmitted &&
            designationAddForm.get('designationName')?.hasError('required')
          "
        >
          <label class="text-danger m-0 custom_label"
            >Designation name is required</label
          >
        </div>
        <span *ngIf="hasErrorFormCheck('name')">
          <app-error [errors]="filterErrorsByKey('name')"></app-error>
        </span>
      </div>
      <div class="mb-3">
        <label for="choices-single-default" class="form-label"
          >Superior Designation ( Reported To )<span class="required-star"
            >*</span
          ></label
        >
        <ng-select
          [items]="designationList"
          formControlName="superiorDesignation"
          id="superiorDesignation"
          placeholder="Select Superior Designation"
          bindLabel="name"
          [(ngModel)]="superiorDesignation"
          class="my-dropdown"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
        <div
          *ngIf="
            isSubmitted &&
            designationAddForm.get('superiorDesignation')?.hasError('required')
          "
        >
          <label class="text-danger m-0 custom_label"
            >Superior designation is required</label
          >
        </div>
      </div>
      <div class="mb-3">
        <label for="orcPercentage" class="col-form-label"
          >ORC Percentage (%)<span class="required-star">*</span></label
        >
        <input
          type="number"
          class="form-control"
          formControlName="orcPercentage"
          placeholder="ORC Percentage"
          (input)="onOrcPercentageInput($event)"
          id="orcPercentage"
          min="0"
          max="100"
          appDisallowMinus
        />
        <div
          *ngIf="
            isSubmitted &&
            designationAddForm.get('orcPercentage')?.hasError('required')
          "
        >
          <label class="text-danger m-0 custom_label"
            >ORC percentage is required</label
          >
        </div>
        <span *ngIf="hasErrorFormCheck('orc')">
          <app-error [errors]="filterErrorsByKey('orc')"></app-error>
        </span>
      </div>
      <div class="mb-3">
        <label for="designationCode" class="col-form-label"
          >Designation Code<span class="required-star">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="designationCode"
          placeholder="Designation Code"
          id="designationCode"
        />
        <div
          *ngIf="
            isSubmitted &&
            designationAddForm.get('designationCode')?.hasError('required')
          "
        >
          <label class="text-danger m-0 custom_label"
            >Designation code is required</label
          >
        </div>
        <span *ngIf="hasErrorFormCheck('code')">
          <app-error [errors]="filterErrorsByKey('code')"></app-error>
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click'); onModalClose()"
      >
        Close
      </button>
      <button type="submit" [disabled]="submittedData" class="btn btn-primary">
        <i *ngIf="submittedData" class="mdi mdi-spin mdi-dots-circle"></i>

        {{ submittedData == true ? "Please Wait..." : "Add Designation" }}
      </button>
    </div>
  </form>
</ng-template>

<ng-template #designationEditModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="designationContentEditModal">
      Edit Designation
    </h5>

    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click'); onModalClose()"
    ></button>
  </div>
  <form (ngSubmit)="EditDesignation()" [formGroup]="designationUpdateForm">
    <div class="modal-body">
      <div class="mb-3">
        <label for="designationName" class="col-form-label"
          >Designation Name<span class="required-star">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="designationName"
          placeholder="Designation Name"
          id="designationName"
        />
        <div
          *ngIf="
            isSubmitted &&
            designationUpdateForm.get('designationName')?.hasError('required')
          "
        >
          <label class="text-danger m-0 custom_label"
            >Designation name is required</label
          >
        </div>
        <span *ngIf="hasErrorFormCheck('name')">
          <app-error [errors]="filterErrorsByKey('name')"></app-error>
        </span>
      </div>
      <span [ngClass]="{ hidden: reportingSuperior }">
        <div class="mb-3">
          <label for="choices-single-default" class="form-label"
            >Superior Designation ( Reported To )<span class="required-star"
              >*</span
            ></label
          >
          <ng-select
            [items]="designationList"
            formControlName="superiorDesignation"
            id="superiorDesignation"
            placeholder="Select Superior Designation"
            bindLabel="name"
            class="my-dropdown"
          >
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.name }}
            </ng-template>
          </ng-select>
          <div
            *ngIf="
              isSubmitted &&
              designationUpdateForm
                .get('superiorDesignation')
                ?.hasError('required')
            "
          >
            <label class="text-danger m-0 custom_label"
              >Superior designation is required</label
            >
          </div>
        </div>
      </span>
      <div class="mb-3">
        <label for="orcPercentage" class="col-form-label"
          >ORC Percentage (%)<span class="required-star">*</span></label
        >
        <input
          type="number"
          class="form-control"
          formControlName="orcPercentage"
          placeholder="ORC Percentage"
          id="orcPercentage"
          min="0"
          max="100"
          (input)="onOrcPercentageInput($event)"
          type="number"
          appDisallowMinus
        />
        <div
          *ngIf="
            isSubmitted &&
            designationUpdateForm.get('orcPercentage')?.hasError('required')
          "
        >
          <label class="text-danger m-0 custom_label"
            >Orc percentage is required</label
          >
        </div>
        <span *ngIf="hasErrorFormCheck('orc')">
          <app-error [errors]="filterErrorsByKey('orc')"></app-error>
        </span>
      </div>
      <div class="mb-3">
        <label for="designationCode" class="col-form-label"
          >Designation Code<span class="required-star">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="designationCode"
          placeholder="Designation Code"
          id="designationCode"
        />
        <div
          *ngIf="
            isSubmitted &&
            designationUpdateForm.get('designationCode')?.hasError('required')
          "
        >
          <label class="text-danger m-0 custom_label"
            >Designation code is required</label
          >
        </div>
        <span *ngIf="hasErrorFormCheck('code')">
          <app-error [errors]="filterErrorsByKey('code')"></app-error>
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <!-- <div class="col-md-6">
        <button
        type="button"
        class="btn btn-sm btn-danger"
        aria-hidden="true"
        (click)="modal.dismiss('Cross click')"
      >Delete</button>
      </div> -->
      <!--  <button
        type="button"
        class="btn btn-danger"
        pButton
        
      >
        Delete
      </button> -->
      <!-- <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click')"
      >
        Close
      </button> -->
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click'); onModalClose()"
      >
        Close
      </button>

      <button type="submit" [disabled]="submittedData" class="btn btn-primary">
        <i *ngIf="submittedData" class="mdi mdi-spin mdi-dots-circle"></i>
        {{ submittedData == true ? "Please wait ..." : "Edit Designation" }}
      </button>
    </div>
  </form>
</ng-template>

<!-- small context menu -->
<p-contextMenu
  #contextmenu
  #cm
  [model]="items"
  (onHide)="onContextMenuHide()"
></p-contextMenu>

<!-- <p-confirmPopup #confirmPopup #conformPop>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      class="btn btn-light custom-mr-5"
      (click)="reject()"
      label="No"
    ></button>
    <button
      type="button"
      pButton
      class="btn btn-danger"
      (click)="accept()"
      label="Yes"
    ></button>
  </ng-template>
</p-confirmPopup> -->
<div class="card flex justify-content-center">
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
      <div
        style="
          background-color: white;
          border-radius: 10px;
          display: flex;
          padding: 2rem;
        "
        class="flex flex-column align-items-center surface-overlay border-round"
      >
        <div
          style="
            width: 5rem;
            height: 5rem;
            border-radius: 60%;
            display: flex;
            background-color: #ee5253 !important;
          "
          class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem"
        >
          <i
            style="color: white; width: 30px; height: 30px; font-size: xx-large"
            class="pi pi-question"
          ></i>
        </div>
        <span
          style="font-weight: bold; font-weight: bold; font-size: 1.4rem"
          class="text-2xl block mb-2 mt-3"
        >
          {{ message.header }}
        </span>
        <p class="mb-0">
          Delete this
          <span style="color: #0a3d2c; font-weight: 500">{{
            message.message
          }}</span>
        </p>
        <div
          style="display: flex; width: 100%; justify-content: center"
          class="mt-4"
        >
          <button
            style="
              width: 8rem;
              height: 2.3rem;
              border-radius: 7px;
              border: 1px solid red;
              margin: 0 5px 0px 5px;
            "
            pButton
            label="Delete"
            (click)="cd.accept()"
            class="deleteCustomButtonPlantation p-2"
          ></button>
          <button
            style="
              width: 8rem;
              height: 2.3rem;
              border-radius: 7px;
              margin: 0 5px 0px 5px;
            "
            pButton
            label="Cancel"
            (click)="cd.reject()"
            class="p-button-outlined p-2"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>
