<div class="row">
  <div class="col-lg-12">
    <h2 class="mb-4 text-primary">
      <i-feather name="credit-card" class="text-primary icon-dual"></i-feather>
      Due Payments
    </h2>
    <div class="card">
      <div class="card-body">
        <div class="my-3 custom-tr">
          <div class="row">
            <div class="col-4">
              <label>Search By Proposal Number</label>
              <input
                type="text"
                [(ngModel)]="searchValue"
                (input)="filteredRequestsHandle()"
                class="form-control"
                id="txtsearch"
                placeholder="Search..."
              />
            </div>
            <div class="col-8 text-end">
              <!-- <button
                type="button"
                class="btn px-5 btn-primary waves-effect waves-light"
                (click)="openPaymentModal(paymentsModal)"
              >
                <i-feather
                  name="plus-circle"
                  class="text-white icon-dual"
                ></i-feather>
                Add New Payment
              </button> -->
            </div>
          </div>
        </div>

        <p-table
          [value]="filteredRequests"
          styleClass="p-datatable"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '85rem' }"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Proposal Number</th>
              <th>Name</th>
              <th class="text-center">Due Amount(LKR)</th>
              <th>Plan Details</th>
              <th>Last Payment Date</th>
              <th class="text-center">Status</th>
              <th style="width: 25%" class="text-end">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-request>
            <tr>
              <td>{{ request.job_code }}</td>
              <td>{{ request.title }} {{ request.first_name }}</td>
              <td class="text-center">
                {{ request.due_amount | number : "1.2-2" }}
              </td>
              <td>{{ request?.plan_details?.plan_name }}</td>
              <td>{{ request.last_payment_date | date : "medium" }}</td>
              <td class="text-center">{{ request?.current_status?.name }}</td>
              <td style="width: 25%" class="text-end">
                <button
                  style="height: 42px; min-height: 42px"
                  type="button"
                  [routerLink]="['/proposals/view/', request.id]"
                  class="btn btn-primary bg-gradient waves-effect waves-light me-2"
                >
                  <i
                    class="pi pi-file text-white"
                    style="font-size: 1rem; stroke-width: 0.4"
                  ></i>
                  View
                </button>

                <button
                  type="button"
                  class="btn btn-primary bg-gradient waves-effect waves-light"
                  (click)="openPaymentModal(paymentsModal, request)"
                >
                  <i-feather
                    name="plus-circle"
                    class="text-white icon-dual"
                  ></i-feather>
                  Add Payment
                </button>
              </td>
              <!-- <td class="text-end">
                  <button
                    type="button"
                    class="btn btn-primary btn-md bg-gradient waves-effect waves-light me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#viewModal"
                    data-bs-whatever="Mary"
                   
                  >
                    <i
                      class="pi pi-file text-white"
                      style="font-size: 1rem; stroke-width: 0.4"
                    ></i>
                    View
                  </button>
                  <button
                    type="button"
                    class="btn btn-warning btn-md bg-gradient waves-effect waves-light me-2"
                  
                  >
                    <i
                      class="pi pi-cog text-white"
                      style="font-size: 1rem; stroke-width: 0.5"
                    ></i>
                    Edit
                  </button>
                  <button
                    type="button"
                    pButton
                    (click)="
                      deleteEmployee(
                        $event,
                        request.id,
                        request.name_with_initials
                      )
                    "
                    class="btn btn-danger btn-md bg-gradient waves-effect waves-light"
                  >
                    <i
                      class="pi pi-trash text-white"
                      style="font-size: 1rem; stroke-width: 0.8"
                    ></i>
                    Delete
                  </button>
                </td -->
            </tr>
          </ng-template>
          <!-- is empty -->
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center" colspan="7">
                <img
                  src="../../../../assets/images/cgl/other/noData.PNG"
                  alt="nodata"
                />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- view Modal -->
<ng-template #paymentsModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="designationContentModal">
      Add Installment Payment
    </h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click'); closeModal()"
    ></button>
  </div>
  <form [formGroup]="paymentAddForm" (ngSubmit)="addNewPayment()">
    <div class="modal-body">
      <div class="mb-3">
        <label for="selectPackage" class="col-form-label"
          >Select Package <span class="required-star">*</span></label
        >
        <ng-select
          [items]="proposalList"
          formControlName="selectedPackage"
          id="selectPackage"
          (change)="onPackageSelect($event)"
          [(ngModel)]="selectedPackage"
          placeholder="Select Package"
          bindLabel="job_code"
          class="my-dropdown"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.job_code }} - {{ item.first_name }}
          </ng-template>
        </ng-select>

        <small
          #planTemplateError
          *ngIf="
            isSubmitted &&
            paymentAddForm.get('selectedPackage')?.hasError('required')
          "
          class="text-danger m-0"
        >
          Select Package to proceed
        </small>
        <span *ngIf="hasErrorFormCheck('user_package_id')">
          <app-error
            [errors]="filterErrorsByKey('user_package_id')"
          ></app-error>
        </span>
      </div>

      <!--  -->
      <div class="card p-3" *ngIf="selectedPackage">
        <h5>Package Details</h5>
        <div class="mt-3">
          <p>
            <strong>&#8226; &nbsp;Job Code:</strong>
            {{ selectedPackage.job_code }}
          </p>
          <p>
            <strong>&#8226; &nbsp;Plan Name:</strong>
            {{ selectedPackage.plan_details?.plan_name }}
          </p>
          <p>
            <strong>&#8226; &nbsp;Due Amount:</strong>
            {{ selectedPackage.due_amount | number : "1.2-2" }}
          </p>
          <p>
            <strong>&#8226; &nbsp;Status:</strong>
            {{ selectedPackage.current_status?.name }}
          </p>
          <p class="m-0">
            <strong>&#8226; &nbsp;Customer Name:</strong>
            {{ selectedPackage.title }} {{ selectedPackage?.first_name }}
            {{ selectedPackage.last_name }}
          </p>
        </div>
      </div>

      <!--  -->

      <div class="row">
        <div class="mb-3 col-6">
          <label for="choices-single-default" class="form-label"
            >Paid Amount <span class="required-star">*</span></label
          >
          <input
            type="number"
            class="form-control"
            formControlName="paidAmount"
            placeholder="Paid Amount"
            id="paid_amount"
          />
          <small
            #paidAmountError
            *ngIf="
              isSubmitted &&
              paymentAddForm.get('paidAmount')?.hasError('required')
            "
            class="text-danger m-0"
          >
            Paid Amount is required
          </small>
          <span *ngIf="hasErrorFormCheck('amount')">
            <app-error [errors]="filterErrorsByKey('amount')"></app-error>
          </span>
        </div>

        <div class="mb-3 col-6">
          <label for="choices-single-default" class="form-label"
            >Payment Reference <span class="required-star">*</span></label
          >
          <input
            type="text"
            class="form-control"
            formControlName="paymentReference"
            placeholder="Payment Reference"
            id="paid_amount"
          />
          <small
            #paymentReferenceError
            *ngIf="
              isSubmitted &&
              paymentAddForm.get('paymentReference')?.hasError('required')
            "
            class="text-danger m-0"
          >
            Payment Reference is required
          </small>
          <span *ngIf="hasErrorFormCheck('reference')">
            <app-error [errors]="filterErrorsByKey('reference')"></app-error>
          </span>
        </div>
      </div>
      <!--  -->
      <div class="row">
        <div class="mb-3 col-6">
          <label for="choices-single-default" class="form-label"
            >Payment Date <span class="required-star">*</span></label
          >
          <!-- <input
            type="date"
            class="form-control"
            formControlName="paymentDate"
            placeholder="Payment Date"
            id="Payment Date"
          /> -->
          <p-calendar
            [iconDisplay]="'input'"
            placeholder="mm/dd/yyyy"
            [showIcon]="true"
            id="Payment Date"
            inputId="icondisplay"
            formControlName="paymentDate"
          />

          <small
            #paymentDateError
            *ngIf="
              isSubmitted &&
              paymentAddForm.get('paymentDate')?.hasError('required')
            "
            class="text-danger m-0"
          >
            Payment Date is required
          </small>
          <span *ngIf="hasErrorFormCheck('payment_date')">
            <app-error [errors]="filterErrorsByKey('payment_date')"></app-error>
          </span>
        </div>

        <div class="mb-3 col-6">
          <label for="choices-single-default" class="form-label"
            >Payment Method <span class="required-star">*</span></label
          >
          <ng-select
            [items]="paymentMethods"
            formControlName="paymentMethod"
            id="payment Method"
            placeholder="Payment Method"
            bindLabel="name"
            class="my-dropdown"
            [(ngModel)]="selectedMethod"
          >
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.name }}
            </ng-template>
          </ng-select>
          <small
            #paymentMethodError
            *ngIf="
              isSubmitted &&
              paymentAddForm.get('paymentMethod')?.hasError('required')
            "
            class="text-danger m-0"
          >
            Payment Method is required
          </small>
          <span *ngIf="hasErrorFormCheck('payment_method')">
            <app-error
              [errors]="filterErrorsByKey('payment_method')"
            ></app-error>
          </span>
        </div>
      </div>
      <!-- description -->
      <div class="row">
        <div class="mb-3 col-6">
          <label for="choices-single-default" class="form-label">Note</label>
          <textarea
            class="form-control"
            formControlName="note"
            rows="2"
            placeholder="Description"
          ></textarea>
        </div>
      </div>

      <div class="d-flex col-6 mt-2">
        <div class="mb-3">
          <input
            class="checkboxStyle"
            type="checkbox"
            id="sendRecipt"
            name="send receipt"
            value="1"
            [checked]="isCheckedCheckBox"
          />
          <label class="checkBoxLabel" for="sendRecipt"> Send Receipt</label>
        </div>
        <div class="mb-3 mx-5">
          <input
            class="checkboxStyle"
            type="checkbox"
            id="downLoadReciept"
            name="download Receipt"
            value="2"
          />
          <label class="checkBoxLabel" for="downLoadReciept">
            Download Receipt</label
          >
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click'); closeModal()"
      >
        Close
      </button>
      <button type="submit" class="btn btn-primary waves-effect waves-light">
        <i *ngIf="submittedData" class="mdi mdi-spin mdi-dots-circle"></i>
        {{ submittedData == true ? "Please Wait..." : "Add Payment" }}
      </button>
    </div>
  </form>
</ng-template>
