import { Component, inject, ViewChildren, QueryList, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { ToastrService } from "ngx-toastr";
import { PlanService } from "src/app/services/plans.service";
import { Router } from "@angular/router";
import { QuotationService } from "src/app/services/quotation.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { FeatherModule } from "angular-feather";
import { NgxUiLoaderService } from "ngx-ui-loader";

import { ErrorComponent } from "src/app/shared/components/errorHandle/error/error.component";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-add",
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    FeatherModule,
    ErrorComponent
  ],
  templateUrl: "./add.component.html",
  styleUrl: "./add.component.scss",
})
export class AddComponent {
  breadCrumbItems!: Array<{}>;
  plansList: any;
  selectedPlan: any;
  quotationAddForm!: FormGroup;
  isSubmitted: boolean = false;
  titles: any;
  title: any;
  submittedData: boolean = false;
  formErrors: { key: string, value: string }[] = []; // form error array

  private planService = inject(PlanService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private quotationService = inject(QuotationService);
  private masterDataService = inject(MasterDataService);
  private ngxLoader = inject(NgxUiLoaderService);

  @ViewChildren('addressError,landLineNumber,emailError,mobileError,titleError,nicError,nameWithInitialError,firstNameError,middleNameError,lastNameError')
  errorElements!: QueryList<ElementRef>;



  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Dashboard" },
      { label: "Plans" },
      { label: "Create", active: true },
    ];
    this.loadTitlesInForm();
    this.getAllPlans();

    this.quotationAddForm = new FormGroup({
      nic: new FormControl("", [Validators.required]),
      title: new FormControl("", [Validators.required]),
      nameWithInitials: new FormControl("", [Validators.required, Validators.maxLength(50)]),
      firstName: new FormControl("", [Validators.required, Validators.maxLength(50)]),
      middleName: new FormControl("", Validators.maxLength(50)),
      lastName: new FormControl("", Validators.maxLength(50)),
      mobileNumber: new FormControl("", [Validators.required]),
      email: new FormControl("", Validators.email),
      landLineNumber: new FormControl(""),
      address: new FormControl("", [Validators.maxLength(100)]),
      planAmounts: new FormArray([]),
    });
    this.generatePlanInputs();
  }



  getAllPlans() {
    this.ngxLoader.start();
    this.planService.findAll().subscribe((resp: any) => {
      this.ngxLoader.stop();
      this.plansList = resp.data;
    });
  }

  generatePlanInputs() {
    const form = <FormArray>(
      (<unknown>this.quotationAddForm.controls["planAmounts"])
    );
    // form.clear();
    form.push(this.addItemSubForm());
    // for (let i = 0; i < years; i++) {
    //     // yearBenefits.push(new FormControl("", Validators.required));
    //     form.push(this.addItemSubForm());
    // }
  }

  addItemSubForm() {
    return new FormGroup({
      plan: new FormControl(null, [Validators.required]),
      amount: new FormControl("", [Validators.required, Validators.max(99999999)]),
    });
  }

  getControls() {
    return (this.quotationAddForm.get("planAmounts") as FormArray).controls;
  }

  loadTitlesInForm() {
    this.ngxLoader.start();
    this.masterDataService.getTitles().subscribe((resp: any) => {
      this.titles = resp.data;
      this.ngxLoader.stop();
    });
  }

  onClickPlanAdd() {
    this.generatePlanInputs();
    /*  const plansArray = this.quotationAddForm.value.planAmounts.map((plnAmount: any, index: any) => {
       console.log('Plan Amount id------------', plnAmount.plan?.id);
       console.log('Plan Amount id------------', plnAmount.amount);
     });
     console.log('Full plan amount :', plansArray); */

  }

  onClickPlanRemove(index: number) {
    const controls = <FormArray>this.quotationAddForm.get("planAmounts");
    controls.removeAt(index);
  }

  //non api call scroll
  scrollToFirstErrorNonApiCall() {
    setTimeout(() => {
      const firstErrorElements = this.errorElements.find((el: { nativeElement: { offsetHeight: number; innerHTML: string; }; }) => {
        return el.nativeElement.offsetHeight > 0 && !!el.nativeElement.innerHTML.trim();
      });

      if (firstErrorElements) {
        firstErrorElements.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }

  //api call scroll
  scrollToFirstError() {
    setTimeout(() => {
      const firstErrorElement = this.errorElements.find((el: { nativeElement: { innerHTML: string; }; }) => {
        return !!el.nativeElement.innerHTML.trim();
      });

      if (firstErrorElement) {
        firstErrorElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }

  // check the error key after boolean
  hasErrorFormCheck(key: string): boolean {
    return this.formErrors.some((err => err.key === key)); // check only boolean
  }

  //filter the form array and check
  filterErrorsByKey(key: string): { key: string, value: string }[] {
    return this.formErrors.filter(error => error.key === key); // return new array
  }


  addNewQuotation() {
    this.isSubmitted = true;
    this.scrollToFirstErrorNonApiCall();
    if (this.quotationAddForm.valid) {
      this.submittedData = true;

      const planAmountsArray = this.quotationAddForm.value.planAmounts.map(
        (planAmount: any, index: number) => ({
          plan_id: planAmount.plan.id,
          amount: planAmount.amount,
        })
        // console.log('Plamn Amount Array');

      );

      console.log("check this out", planAmountsArray);

      const payload = {
        title_id: this.quotationAddForm.value.title.id,
        name_with_initials: this.quotationAddForm.value.nameWithInitials,
        first_name: this.quotationAddForm.value.firstName,
        middle_name: this.quotationAddForm.value.middleName,
        last_name: this.quotationAddForm.value.lastName,
        mobile_number: this.quotationAddForm.value.mobileNumber,
        email: this.quotationAddForm.value.email,
        nic: this.quotationAddForm.value.nic,
        landline_number: this.quotationAddForm.value.landLineNumber,
        address: this.quotationAddForm.value.address,
        plans: planAmountsArray,
      };
      this.ngxLoader.start();

      /*   this.quotationService.create(payload).subscribe((data: Blob) => {
          this.isSubmitted = false;
          this.submittedData = false;
  
          const blob = new Blob([data], { type: "application/pdf" });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "quotation_" + this.quotationAddForm.value.nic + ".pdf"; // Specify filename here with .pdf extension
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(a);
  
          this.toastr.success("Quotation generate successfully.", "success");
          this.ngxLoader.stop();
          this.router.navigate(["/quotations"]);
        }); */



      this.quotationService.create(payload).subscribe({
        next: (data: Blob) => {
          this.isSubmitted = false;
          this.submittedData = false;

          const blob = new Blob([data], { type: "application/pdf" });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "quotation_" + this.quotationAddForm.value.nic + ".pdf"; // Specify filename here with .pdf extension
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(a);

          this.toastr.success("Quotation generated successfully", "Success");
          this.ngxLoader.stop();
          this.router.navigate(["/quotations"]);
        },
        error: (error: HttpErrorResponse) => {
          console.log('Quotation Error :', error);
          this.isSubmitted = false;
          this.submittedData = false;
          this.formErrors = [];
          if (error.status === 422 && error.error) {
            console.log('Quotation add error', error.error);

            for (const [key, value] of Object.entries(error?.error)) {
              if (typeof value === 'object' && value != null) {
                for (const [nestedKey, nestedValue] of Object.entries(value)) {
                  this.formErrors.push({ key: nestedKey, value: nestedValue as string });
                  this.submittedData = false;
                }
                this.scrollToFirstError();
              } else {
                this.formErrors.push({ key: key, value: value as string })
                this.submittedData = false;
                this.scrollToFirstError();
              }
            }
          } else {
            this.toastr.error(error.message || "Something went wrong, Please try again later.", "Error!");
          }
          const errorMessage = error?.error?.message || "Failed to generate quotation. Please try again.";
          //this.toastr.error(errorMessage, "Error");
          this.ngxLoader.stop();
        }
      });

    }
  }

  onClickCancel() {
    this.router.navigate(["/quotations"]);
  }
}
