import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../core/models/api-response.model';

@Injectable({ providedIn: 'root' })
export class PaymentService {

    private http = inject(HttpClient);
    private API_URL_PREFIX: string = environment.apiURL + '/' + 'admin/payments';
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    findAll(queryParams?: any) {
        let params = new HttpParams();
        if (queryParams) {
            for (const key in queryParams) {
                if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
                    params = params.set(key, queryParams[key]);
                }
            }
        }
        return this.http.get(this.API_URL_PREFIX, {
            headers: this.httpOptions.headers,
            params: params
        });
    }


    findById(id: string) {
        return this.http.get(this.API_URL_PREFIX + '/by-package/' + id, this.httpOptions);
    }

    create(payload: any) {
        return this.http.post(this.API_URL_PREFIX, payload, this.httpOptions);
    }

}
