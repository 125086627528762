<!-- <app-breadcrumbs
  title="Edit Plan"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs> -->

<div class="row">
  <div class="col-lg-12">
    <span class="d-flex">
      <i
        (click)="cancelOnAction()"
        class="pi pi-arrow-left"
        style="
          font-size: 1.4rem;
          stroke-width: 0.4;
          font-size: 1.4rem;
          margin: 5px 15px 0px 1px;
          cursor: pointer;
          color: #878a99;
        "
      ></i>
      <h2 class="mb-4 text-primary">Edit Plan</h2>
    </span>

    <div class="card">
      <form
        [formGroup]="planUpdateForm"
        (ngSubmit)="EditPlan()"
        enctype="multipart/form-data"
      >
        <div class="card-body">
          <div class="live-preview">
            <div class="row gy-4">
              <div class="col-xxl-12 col-md-12">
                <label for="choices-single-default" class="form-label"
                  >Plan Template</label
                >
                <ng-select
                  [items]="planTemplates"
                  formControlName="planTemplate"
                  placeholder="Select a Plan Template"
                  id="planTemplate"
                  bindLabel="name"
                  class="my-dropdown"
                >
                  <ng-template ng-optgroup-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <p
                  *ngIf="
                    isSubmitted &&
                    planUpdateForm.get('planTemplate')?.hasError('required')
                  "
                  class="text-danger"
                >
                  Category is required
                </p>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="basiInput" class="form-label"
                    >Name <span class="required-star">*</span></label
                  >
                  <input
                    type="text"
                    appTextInput="text"
                    class="form-control"
                    formControlName="name"
                    id="basiInput"
                    placeholder="Plan Name"
                  />
                  <p
                    #planNameError
                    *ngIf="
                      isSubmitted &&
                      planUpdateForm.get('name')?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Name is required
                  </p>
                  <span *ngIf="hasErrorFormCheck('name')" #planNameError>
                    <app-error [errors]="filterErrorsByKey('name')"></app-error>
                  </span>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="basiInput" class="form-label"
                    >Minimum Amount <span class="required-star">*</span></label
                  >
                  <input
                    appDisallowMinus
                    type="number"
                    class="form-control"
                    formControlName="investmentAmount"
                    id="basiInput"
                    placeholder="Total Investment"
                  />
                  <p
                    #minimumAmountError
                    *ngIf="
                      isSubmitted &&
                      planUpdateForm
                        .get('investmentAmount')
                        ?.hasError('required')
                    "
                    class="text-danger m-0"
                  >
                    Investment amount is required
                  </p>
                  <span
                    *ngIf="hasErrorFormCheck('minimum_amount')"
                    #minimumAmountError
                  >
                    <app-error
                      [errors]="filterErrorsByKey('minimum_amount')"
                    ></app-error>
                  </span>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-6 col-md-6">
                <div>
                  <label for="basiInput" class="form-label"
                    >Duration in Months</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    formControlName="duration"
                    placeholder="Duration in Months"
                    (keyup)="
                      selectedPlanTemplate?.name !==
                      'Percentage + Profit + Capital'
                        ? onDurationChange($event)
                        : null
                    "
                    id="basiInput"
                  />
                  <p
                    *ngIf="
                      isSubmitted &&
                      planUpdateForm.get('duration')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Duration is required
                  </p>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12 col-md-12" *ngIf="selectedPlanTemplate">
                <hr />

                <div
                  class="row"
                  id="profit-benefit"
                  *ngIf="selectedPlanTemplate?.id === 3"
                >
                  <div class="col-xxl-6 col-md-6">
                    <div>
                      <label for="basiInput" class="form-label"
                        >Profit Per Month (%)</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        formControlName="profitPerMonth"
                        id="basiInput"
                        placeholder="Profit"
                      />
                      <p
                        *ngIf="
                          isSubmitted &&
                          planUpdateForm
                            .get('profitPerMonth')
                            ?.hasError('required')
                        "
                        class="text-danger"
                      >
                        Profit per month is required
                      </p>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-xxl-6 col-md-6">
                    <div>
                      <label for="basiInput" class="form-label"
                        >Benefit Per Month (%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        formControlName="benefitPerYear"
                        id="basiInput"
                        placeholder="Benefit"
                      />
                      <p
                        *ngIf="
                          isSubmitted &&
                          planUpdateForm
                            .get('benefitPerYear')
                            ?.hasError('required')
                        "
                        class="text-danger"
                      >
                        Benefit per month is required
                      </p>
                    </div>
                  </div>
                  <!--end col-->
                </div>

                <div
                  class="row"
                  id="profit-benefit"
                  *ngIf="selectedPlanTemplate?.id === 4"
                >
                  <div class="col-xxl-6 col-md-6">
                    <div>
                      <label for="basiInput" class="form-label"
                        >Benefit Per Month (%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        formControlName="benefitPerYear"
                        id="basiInput"
                        placeholder="Benefit"
                      />
                      <p
                        *ngIf="
                          isSubmitted &&
                          planUpdateForm
                            .get('benefitPerYear')
                            ?.hasError('required')
                        "
                        class="text-danger"
                      >
                        Benefit per month is required
                      </p>
                    </div>
                  </div>
                  <!--end col-->
                </div>

                <div
                  id="benefit-only"
                  *ngIf="
                    selectedPlanTemplate?.id == 1 ||
                    selectedPlanTemplate?.id == 2
                  "
                >
                  <div>
                    <label for="basiInput" class="form-label"
                      >Commission Rates</label
                    >
                  </div>

                  <div formArrayName="yearBenefits">
                    <div
                      *ngFor="let yearControl of getControls(); let i = index"
                      class="col-xxl-12 col-md-12"
                    >
                      <div
                        class="row col-xxl-12 col-md-12 mt-2"
                        [formGroupName]="i"
                      >
                        <div class="col-md-4 align-vertically-center">
                          <label class="form-label"
                            >{{ i + 1
                            }}<span class="ordinal-suffix">{{
                              getOrdinalSuffix(i + 1).slice(-2)
                            }}</span>
                            Year</label
                          >
                        </div>
                        <div class="col-md-8 custom-mb-20">
                          <label
                            for="yearBenefitInput-{{ i }}"
                            class="form-label"
                            >Benefit (%)</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            formControlName="benefit"
                            id="yearBenefitInput-{{ i }}"
                            placeholder="Benefit"
                          />
                          <p
                            *ngIf="
                              isSubmitted &&
                              yearControl?.get('benefit')?.hasError('required')
                            "
                            class="text-danger"
                          >
                            Benefit value is required
                          </p>
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
        <div class="card-footer custom-tr text-end">
          <button
            type="button"
            (click)="cancelOnAction()"
            class="btn btn-light waves-effect waves-light me-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light"
            [disabled]="submittedData"
          >
            <i *ngIf="submittedData" class="mdi mdi-spin mdi-dots-circle"></i>
            {{ submittedData == true ? "Please wait..." : "Edit Plan" }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <!--end col-->
</div>
<!--end row-->
