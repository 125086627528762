<!-- <app-breadcrumbs title="" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs> -->

<div class="row">
  <div class="col-lg-12">
    <h2 class="mb-4 text-primary">
      <i-feather name="book-open" class="text-primary icon-dual"></i-feather>
      Plans
    </h2>
    <div class="card">
      <div class="card-header custom-tr">
        <div class="row">
          <div class="col-6">
            <label for="txtsearch">Search By Plans Name Or Plans Code</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="searchValue"
              (input)="filterRequests()"
              id="txtsearch"
              placeholder="Search..."
            />
          </div>
          <div class="col-6 text-end">
            <button
              type="button"
              class="btn btn-primary waves-effect waves-light px-5"
              [routerLink]="['/plans/add']"
            >
              <i-feather
                name="plus-circle"
                class="text-white icon-dual"
              ></i-feather>
              Add New Plan
            </button>
          </div>
        </div>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <p-table
          [value]="filteredRequests"
          styleClass="p-datatable"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="">Name</th>
              <th style="">Code</th>
              <th style="">Duration</th>
              <th class="text-center">Type</th>
              <th class="text-center">Proposal Count</th>
              <th style="" class="text-end">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-request>
            <tr>
              <td>{{ request.name }}</td>
              <td>{{ request.code }}</td>
              <!--   <td>
                {{ request.duration }}
                {{ request.duration === 1 ? "Month" : "Months" }}
              </td> -->
              <td>
                {{
                  request.duration === "1"
                    ? request.duration + " Month"
                    : request.duration + " Months"
                }}
              </td>
              <td class="text-center">{{ request.plan_template.name }}</td>
              <td class="text-center">
                {{ request.count }}
              </td>
              <td class="text-end">
                <button
                  type="button"
                  class="btn btn-primary btn-md bg-gradient waves-effect waves-light"
                  data-bs-toggle="modal"
                  data-bs-target="#planContentModal"
                  data-bs-whatever="Mary"
                  (click)="openPlanViewModel(planViewModal, request.id)"
                >
                  <i
                    class="pi pi-file text-white"
                    style="font-size: 1rem; stroke-width: 0.4"
                  ></i>

                  View
                </button>
                <button
                  type="button"
                  class="btn btn-warning btn-md bg-gradient waves-effect waves-light mx-2"
                  [routerLink]="['/plans/edit', request.id]"
                >
                  <i
                    class="pi pi-cog text-white"
                    style="font-size: 1rem; stroke-width: 0.5"
                  ></i>
                  Edit
                </button>
                <button
                  type="button"
                  pButton
                  (click)="deletePlan($event, request.id, request.name)"
                  class="btn btn-danger btn-md bg-gradient waves-effect waves-light"
                >
                  <i
                    class="pi pi-trash text-white"
                    style="font-size: 1rem; stroke-width: 0.8"
                  ></i>
                  Delete
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center" colspan="7">
                <img
                  src="../../../../assets/images/cgl/other/noData.PNG"
                  alt="nodata"
                />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<ng-template #planViewModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="planContentModal">Plan Details</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>

  <div class="modal-body">
    <div class="row col-md-12">
      <div class="col-md-6">
        <label for="designationName" class="col-form-label">Name:</label>
      </div>
      <div class="col-md-6">
        <label for="designationName" class="col-form-label">{{
          viewedPlan.name
        }}</label>
      </div>
    </div>
    <div class="row col-md-12">
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
          >Plan Template:</label
        >
      </div>
      <div class="col-md-6">
        <label for="designationName" class="col-form-label">{{
          viewedPlan.plan_template.name
        }}</label>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <label for="designationName" class="col-form-label"
          >Minimum Amount:</label
        >
      </div>
      <div class="col-md-6">
        <label for="designationName" class="col-form-label">{{
          viewedPlan.minimum_amount
        }}</label>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <label for="designationName" class="col-form-label">Duration: </label>
      </div>
      <div class="col-md-6">
        <label for="designationName" class="col-form-label">{{
          viewedPlan.duration === "1"
            ? viewedPlan.duration + " Month"
            : viewedPlan.duration + " Months"
        }}</label>
      </div>
    </div>
  </div>
</ng-template>

<!-- <p-confirmPopup>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      class="btn btn-light custom-mr-5"
      (click)="reject()"
      label="No"
    ></button>
    <button
      type="button"
      pButton
      class="btn btn-danger"
      (click)="accept()"
      label="Yes"
    ></button>
  </ng-template>
</p-confirmPopup>  -->
<div class="card flex justify-content-center">
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
      <div
        style="
          background-color: white;
          border-radius: 10px;
          display: flex;
          padding: 2rem;
        "
        class="flex flex-column align-items-center surface-overlay border-round"
      >
        <div
          style="
            width: 5rem;
            height: 5rem;
            border-radius: 60%;
            display: flex;
            background-color: #ee5253 !important;
          "
          class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem"
        >
          <i
            style="color: white; width: 30px; height: 30px; font-size: xx-large"
            class="pi pi-question"
          ></i>
        </div>
        <span
          style="font-weight: bold; font-weight: bold; font-size: 1.4rem"
          class="text-2xl block mb-2 mt-3"
        >
          {{ message.header }}
        </span>
        <p class="mb-0">
          Delete
          <span style="color: #0a3d2c; font-weight: 500">{{
            message.message | titlecase
          }}</span>
        </p>
        <div
          style="display: flex; width: 100%; justify-content: center"
          class="mt-4"
        >
          <button
            style="
              width: 8rem;
              height: 2.3rem;
              border-radius: 7px;
              border: 1px solid red;
              margin: 0 5px 0px 5px;
            "
            pButton
            label="Delete"
            (click)="cd.accept()"
            class="deleteCustomButtonPlantation p-2"
          ></button>
          <button
            style="
              width: 8rem;
              height: 2.3rem;
              border-radius: 7px;
              margin: 0 5px 0px 5px;
            "
            pButton
            label="Cancel"
            (click)="cd.reject()"
            class="p-button-outlined p-2"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>
