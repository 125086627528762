import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule, NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

// Swiper Slider
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterModule } from '@angular/router';
// Counter
import { CountUpModule } from 'ngx-countup';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ClientLogoComponent } from './landing/index/client-logo/client-logo.component';
import { ServicesComponent } from './landing/index/services/services.component';
import { CollectionComponent } from './landing/index/collection/collection.component';
import { CtaComponent } from './landing/index/cta/cta.component';
import { DesignedComponent } from './landing/index/designed/designed.component';
import { PlanComponent } from './landing/index/plan/plan.component';
import { FaqsComponent } from './landing/index/faqs/faqs.component';
import { ReviewComponent } from './landing/index/review/review.component';
import { CounterComponent } from './landing/index/counter/counter.component';
import { WorkProcessComponent } from './landing/index/work-process/work-process.component';
import { TeamComponent } from './landing/index/team/team.component';
import { ContactComponent } from './landing/index/contact/contact.component';
import { FooterComponent } from './landing/index/footer/footer.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { TextInputDirective } from './textinput.directive';
import { MobileNumberMaskDirective } from './directives/mobile-number-mask.directive';
import { MaxNumberDirective } from './directives/max-number.directive';
import { NicToDobDirective } from './directives/nic-to-dob.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive'


// NFT Landing 
import { MarketPlaceComponent } from './landing/nft/market-place/market-place.component';
import { WalletComponent } from './landing/nft/wallet/wallet.component';
import { FeaturesComponent } from './landing/nft/features/features.component';
import { CategoriesComponent } from './landing/nft/categories/categories.component';
import { DiscoverComponent } from './landing/nft/discover/discover.component';
import { TopCreatorComponent } from './landing/nft/top-creator/top-creator.component';
import { CapitalizePipe } from '../pipes/capitalize.pipe';

// Job Landing 
import { BlogComponent } from './landing/job/blog/blog.component';
import { CandidateComponent } from './landing/job/candidate/candidate.component';
import { FindjobsComponent } from './landing/job/findjobs/findjobs.component';
import { JobFooterComponent } from './landing/job/job-footer/job-footer.component';
import { JobcategoriesComponent } from './landing/job/jobcategories/jobcategories.component';
import { ProgressComponent } from './landing/job/progress/progress.component';
import { LandingScrollspyDirective } from './landingscrollspy.directive';
import { HierarchyBreadcrumbComponent } from './components/breadcrumbs/hierarchy-breadcrumb/hierarchy-breadcrumb.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';

import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
import { DisallowMinusDirective } from './directives/disallow-minus-key.directive';
import { DisallowFullsTop } from './directives/disallow-full-stop.directive';
import { StatusConfirmationComponent } from './components/status-confirmation/status-confirmation.component';
import { ValidateEmailExtra } from './directives/email-validate.directive'

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    TopCreatorComponent,
    TextInputDirective,
    ClientLogoComponent,
    ServicesComponent,
    CollectionComponent,
    CtaComponent,
    DesignedComponent,
    PlanComponent,
    FaqsComponent,
    ReviewComponent,
    CounterComponent,
    WorkProcessComponent,
    TeamComponent,
    ContactComponent,
    FooterComponent,
    ScrollspyDirective,
    TextInputDirective,
    LandingScrollspyDirective,
    MarketPlaceComponent,
    WalletComponent,
    FeaturesComponent,
    CategoriesComponent,
    DiscoverComponent,
    BlogComponent,
    CandidateComponent,
    FindjobsComponent,
    JobFooterComponent,
    JobcategoriesComponent,
    ProgressComponent,
    MobileNumberMaskDirective,
    DisallowMinusDirective,
    MaxNumberDirective,
    NicToDobDirective,
    DisallowFullsTop,
    OnlyNumbersDirective,
    ValidateEmailExtra,
    CapitalizePipe
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbDropdownModule,
    SlickCarouselModule,
    CountUpModule,
    RouterModule,
    HierarchyBreadcrumbComponent,
    ConfirmationComponent,
    DeleteConfirmationComponent,
    StatusConfirmationComponent

  ],
  exports: [
    BreadcrumbsComponent,
    HierarchyBreadcrumbComponent,
    ClientLogoComponent,
    ServicesComponent,
    CollectionComponent,
    CtaComponent,
    DesignedComponent,
    PlanComponent,
    FaqsComponent,
    ReviewComponent,
    CounterComponent,
    WorkProcessComponent,
    TeamComponent,
    ContactComponent,
    FooterComponent,
    ScrollspyDirective,
    LandingScrollspyDirective,
    WalletComponent,
    MarketPlaceComponent,
    FeaturesComponent,
    CategoriesComponent,
    DiscoverComponent,
    TextInputDirective,
    ProgressComponent,
    FindjobsComponent,
    CandidateComponent,
    BlogComponent,
    JobcategoriesComponent,
    MobileNumberMaskDirective,
    JobFooterComponent,
    ConfirmationComponent,
    DisallowMinusDirective,
    DeleteConfirmationComponent,
    MaxNumberDirective,
    NicToDobDirective,
    DisallowFullsTop,
    StatusConfirmationComponent,
    OnlyNumbersDirective,
    ValidateEmailExtra,
    CapitalizePipe
  ]
})
export class SharedModule { }
