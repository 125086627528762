<div class="row">
  <div class="col-lg-12">
    <h2 class="mb-4 text-primary">
      <i-feather name="award" class="text-primary icon-dual"></i-feather>
      Benefits
    </h2>
    <div class="card">
      <div class="card-body">
        <div class="my-3 custom-tr">
          <div class="row">
            <div class="col-4">
              <label>Filter By Payment Status</label>
              <ng-select
                [clearable]="false"
                [items]="paymentStatuses"
                [(ngModel)]="selectedPaymentStatus"
                (change)="onPaymentStatusChange($event)"
                placeholder="Select a Payment Status"
                id="paymentStatus"
                bindLabel="name"
                class="my-dropdown"
              >
                <ng-template ng-optgroup-tmp let-item="item">
                  {{ item.name }}
                </ng-template>
              </ng-select>
              <!-- <ng-select placeholder="Payment Status" class="my-dropdown">
                <ng-template ng-optgroup-tmp let-item="item">
                  {{ item.name }}
                </ng-template>
              </ng-select> -->
            </div>
            <!-- <div class="col-8 text-end">
              <button
                type="button"
                class="btn px-5 btn-primary waves-effect waves-light"
                [routerLink]="['/employees/add']"
              >
                <i-feather
                  name="plus-circle"
                  class="text-white icon-dual"
                ></i-feather>
                Add New Employee
              </button>
            </div> -->
          </div>
        </div>

        <p-table
          [value]="benefitList"
          styleClass="p-datatable"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '60rem' }"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <ng-template pTemplate="header">
            <tr>
              <!-- <th>Benefit ID</th> -->
              <th>Benefit Type</th>
              <th>Customer Name</th>
              <th>Package Code</th>
              <th>Payment Term</th>
              <th>Package Total</th>
              <th class="text-center">Payment Status</th>
              <th style="min-width: 8rem" class="text-end">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-request>
            <tr>
              <!--  <td>{{ request.id }}</td> -->
              <td>{{ request.benefit_type }}</td>
              <td>{{ request.customer_name }}</td>
              <td>{{ request.package_code }}</td>
              <td>{{ request.payment_term }}</td>
              <td>{{ request.package_total }}</td>
              <td class="text-center">
                <p-tag
                  [value]="request.paid_at === null ? 'Unpaid' : 'Paid'"
                  [severity]="request.paid_at === null ? 'danger' : 'success'"
                ></p-tag>
              </td>
              <td class="text-end">
                <button
                  type="button"
                  class="btn btn-primary btn-md bg-gradient waves-effect waves-light"
                  data-bs-toggle="modal"
                  data-bs-target="#viewModal"
                  data-bs-whatever="Mary"
                  (click)="openBenefitModel(request, benefitDetail)"
                >
                  <i
                    class="pi pi-file text-white"
                    style="font-size: 1rem; stroke-width: 0.4"
                  ></i>
                  View
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- is empty -->
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center" colspan="7">
                <img
                  src="../../../../assets/images/cgl/other/noData.PNG"
                  alt="nodata"
                />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- benefit detail -->

<ng-template #benefitDetail role="document" let-modal>
  <div class="modal-header pb-1"></div>

  <div class="modal-body modal-xl pt-2">
    <div class="container-fluid">
      <div class="profile-foreground position-relative mx-n4 mt-n4">
        <div class="profile-wid-bg">
          <img
            src="../../../assets/images/cgl/other/bg_plantsAllView.jpeg"
            alt=""
            class="profile-wid-img"
          />
        </div>
      </div>
      <div class="pt-4 profile-wrapper">
        <div class="row g-4">
          <!--end col-->
          <div class="col">
            <div class="p-2">
              <div class="row m-0">
                <div class="col-10 p-0">
                  <div class="row m-0">
                    <h3 class="text-white mb-1 p-0">Benefit Details</h3>
                  </div>
                </div>
                <div class="col-2 p-0 text-end">
                  <img
                    class="paid_image"
                    src="../../../assets/images/cgl/logo/paid.png"
                    alt="paid"
                    [ngStyle]="{
                      display: isDisplay === null ? 'none' : 'block'
                    }"
                  />
                  <!--  <button
                      (click)="modal.dismiss('Cross click')"
                      class="btn btn-secondary"
                      aria-hidden="true"
                    >
                      <i class="align-bottom"></i> Close
                    </button> -->
                  <i
                    class="pi pi-times"
                    style="font-size: 27px; color: azure; cursor: pointer"
                    (click)="modal.dismiss('Cross click')"
                  ></i>
                </div>
              </div>

              <!-- <p class="text-white text-opacity-75"></p> -->
              <!--  <div class="hstack text-white-50 gap-1">
                <div class="me-2">
                  <a
                      [href]="'tel:' + viewModalData.mobile_number"
                      class="text-white text-opacity-75"
                      style="text-decoration: underline"
                    >
                      <i
                        class="text-white text-opacity-75 fs-16 align-middle"
                      ></i>
                      Harsha Bandara
                    </a> 
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!--end row-->
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div>
            <div class="d-flex profile-wrapper">
              <!-- Nav tabs -->
              <ul
                class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                role="tablist"
              ></ul>
              <div class="flex-shrink-0">
                <!-- <a
                    (click)="navigateToEditEmployee(viewModalData.id)"
                    class="btn btn-success"
                    ><i class="ri-edit-box-line align-bottom"></i> Edit
                    Employee</a
                  > -->
              </div>
            </div>
            <!-- Tab panes -->
            <div class="tab-content pt-3 text-muted">
              <div class="tab-pane active" id="overview-tab" role="tabpanel">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col-xxl-12 col-md-12">
                        <div class="card h-100 shadow extraStyle">
                          <div class="card-body extraPadding">
                            <h5 class="card-title">Customer Details</h5>
                            <div class="table-responsive">
                              <table class="table table-borderless mb-0">
                                <tbody class="employee-tbl">
                                  <tr>
                                    <th
                                      class="ps-0 text-muted th_width"
                                      scope="row"
                                    >
                                      Customer Name :
                                    </th>
                                    <td class="text-muted">
                                      {{
                                        selectedBenefit.customer_name
                                          ? selectedBenefit.customer_name
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <!--  -->
                        </div>
                      </div>

                      <div class="col-xxl-12 col-md-12 mt-2">
                        <div class="card h-100 shadow extraStyle">
                          <div class="card-body extraPadding">
                            <h5 class="card-title mb-2">Package Details</h5>
                            <div class="table-responsive">
                              <table class="table table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <th
                                      class="ps-0 text-muted th_width"
                                      scope="row"
                                    >
                                      Package Code :
                                    </th>
                                    <td class="text-muted">
                                      {{
                                        selectedBenefit.package_code
                                          ? selectedBenefit.package_code
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 text-muted th_width"
                                      scope="row"
                                    >
                                      Branch Name :
                                    </th>
                                    <td class="text-muted">
                                      {{
                                        selectedBenefit.package_name
                                          ? selectedBenefit.package_name
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 text-muted th_width"
                                      scope="row"
                                    >
                                      Package Total :
                                    </th>
                                    <td class="text-muted">
                                      {{
                                        selectedBenefit.package_total
                                          ? selectedBenefit.package_total
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-xxl-12 col-md-12">
                        <div class="card h-100 shadow extraStyle">
                          <div class="card-body extraPadding">
                            <h5 class="card-title mb-2">
                              Package Payments History
                            </h5>
                            <div class="table-responsive">
                              <table class="table table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <th
                                      class="ps-0 text-muted th_width"
                                      scope="row"
                                    >
                                      Payment Term :
                                    </th>
                                    <td class="text-muted">
                                      {{
                                        selectedBenefit.payment_term
                                          ? selectedBenefit.payment_term
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      class="ps-0 text-muted th_width"
                                      scope="row"
                                    >
                                      Amount :
                                    </th>
                                    <td class="text-muted">
                                      {{
                                        selectedBenefit.amount
                                          ? selectedBenefit.amount
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    [ngStyle]="{
                                      display:
                                        isDisplay == null ? 'none' : 'table-row'
                                    }"
                                  >
                                    <th
                                      class="ps-0 text-muted th_width"
                                      scope="row"
                                    >
                                      Paid At :
                                    </th>
                                    <td class="text-muted">
                                      {{
                                        selectedBenefit.paid_at
                                          ? selectedBenefit.paid_at
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    [ngStyle]="{
                                      display:
                                        isDisplayPaymentReference == null
                                          ? 'none'
                                          : 'table-row'
                                    }"
                                  >
                                    <th
                                      class="ps-0 text-muted th_width"
                                      scope="row"
                                    >
                                      Payment Reference :
                                    </th>
                                    <td class="text-muted">
                                      {{
                                        selectedBenefit.payment_reference
                                          ? selectedBenefit.payment_reference
                                          : "-"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!selectedBenefit.payment_reference">
                      <div class="mb-3 mt-2">
                        <label
                          for="reference"
                          style="color: #495057"
                          class="col-form-label card-title"
                          >Payment Reference:</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Reference"
                          id="reference"
                          [(ngModel)]="paymentReference"
                        />
                        <div *ngIf="referenceError">
                          <small class="text-danger m-0"
                            >Reference is required</small
                          >
                        </div>
                      </div>
                      <!-- <button type="button" class="btn btn-primary" (click)="onClickPaid()">Mark as paid</button> -->
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                      <button
                        type="button"
                        (click)="modal.dismiss('Cross click')"
                        class="btn btn-light waves-effect waves-light"
                        [ngStyle]="{
                          margin: isDisplay == null ? '0px 6px 0px 6px' : '0px'
                        }"
                      >
                        Cancel
                      </button>

                      <span *ngIf="!selectedBenefit.payment_reference">
                        <button
                          type="button"
                          class="btn btn-primary"
                          (click)="onClickPaid()"
                        >
                          Mark as paid
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <!--end row-->
              </div>
            </div>
            <!--end tab-content-->
          </div>
        </div>

        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
</ng-template>
<app-confirmation
  (approve)="handleApproval()"
  (cancel)="handleCancel()"
  #confirmDialog
></app-confirmation>
