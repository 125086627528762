<!-- <app-breadcrumbs title="" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs> -->

<div class="row">
  <div class="col-lg-12">
    <h2 class="mb-4 text-primary">
      <i-feather name="triangle" class="text-primary icon-dual"></i-feather>
      Admin Hierarchy
    </h2>

    <div class="card">
      <div class="card-header custom-tr text-end my-3">
        <button
          type="button"
          class="btn btn-primary waves-effect waves-light px-5"
          data-bs-toggle="modal"
          data-bs-target="#hierarchyContentModal"
          data-bs-whatever="Mary"
          (click)="openHierarchyModal(hierarchyAddModal)"
        >
          <i-feather
            name="plus-circle"
            class="text-white icon-dual"
          ></i-feather>
          Add New Hierarchy
        </button>
      </div>

      <div class="card-body">
        <p-table
          [value]="filteredRequests"
          styleClass="p-datatable"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th class="text-center">Admin Level</th>
              <th>Structure</th>
              <th class="text-end">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-request>
            <tr>
              <td>{{ request.name }}</td>
              <td class="text-center">
                <p-badge
                  class="p_badge_color"
                  [value]="request.administrative_level"
                ></p-badge>
              </td>
              <td>
                <app-hierarchy-breadcrumb
                  [breadcrumbItems]="request.breadcrumb"
                ></app-hierarchy-breadcrumb>
              </td>
              <td class="text-end">
                <button
                  type="button"
                  class="btn btn-warning btn-md bg-gradient waves-effect waves-light"
                  (click)="
                    openHierarchyUpdateModal(hierarchyUpdateModal, request)
                  "
                >
                  <i
                    class="pi pi-cog text-white"
                    style="font-size: 1rem; stroke-width: 0.5"
                  ></i>
                  Edit
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center" colspan="5">
                <img
                  src="../../../../assets/images/cgl/other/noData.PNG"
                  alt="nodata"
                />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->

<ng-template #hierarchyAddModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="hierarchyContentModal">Add New Hierarchy</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click'); onCloseModel()"
    ></button>
  </div>
  <form (ngSubmit)="createNew()" [formGroup]="hierarchyAddForm">
    <div class="modal-body">
      <div class="mb-3">
        <label for="choices-single-default" class="form-label"
          >Admin Level<span class="required-star">*</span></label
        >
        <ng-select
          [items]="adminLevels"
          formControlName="adminLevel"
          placeholder="Select Admin Level"
          id="adminLevel"
          (change)="loadHierarchy($event)"
          bindLabel="name"
          [(ngModel)]="adminLevel"
          class="my-dropdown"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
        <p
          *ngIf="
            isSubmitted &&
            hierarchyAddForm.get('adminLevel')?.hasError('required')
          "
          class="text-danger m-0"
        >
          Please select an admin level
        </p>
        <span *ngIf="hasErrorFormCheck('administrative_level_id')">
          <app-error
            [errors]="filterErrorsByKey('administrative_level_id')"
          ></app-error>
        </span>
      </div>
      <div class="mb-3" *ngIf="parentVisible">
        <label for="choices-single-default" class="form-label"
          >Parent Name<span class="required-star">*</span></label
        >
        <ng-select
          [items]="filteredHierarchyItems"
          placeholder="Select Parent"
          formControlName="parent"
          id="parent"
          bindLabel="name"
          [ngModel]="parent"
          class="my-dropdown"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
        <p
          *ngIf="
            isSubmitted && hierarchyAddForm.get('parent')?.hasError('required')
          "
          class="text-danger"
        >
          Parent is required
        </p>
        <span *ngIf="hasErrorFormCheck('parent_id')">
          <app-error [errors]="filterErrorsByKey('parent_id')"></app-error>
        </span>
      </div>
      <div class="mb-3">
        <label for="name" class="col-form-label name-label"
          >Name<span class="required-star">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="name"
          placeholder="Name"
          id="name"
        />
        <div
          *ngIf="
            isSubmitted && hierarchyAddForm.get('name')?.hasError('required')
          "
        >
          <label class="text-danger m-0">Name is required</label>
        </div>
        <span *ngIf="hasErrorFormCheck('name')">
          <app-error [errors]="filterErrorsByKey('name')"></app-error>
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click'); onCloseModel()"
      >
        Close
      </button>
      <button
        type="submit"
        [disabled]="submittedData"
        class="btn btn-primary waves-effect waves-light"
      >
        <i *ngIf="submittedData" class="mdi mdi-spin mdi-dots-circle"></i>
        {{ submittedData == true ? "Please wait..." : "Add" }}
      </button>
    </div>
  </form>
</ng-template>

<!-- for Update The Hirachy modal -->

<ng-template #hierarchyUpdateModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="hierarchyContentModal">Update Hierarchy</h5>
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click'); onCloseModel()"
    ></button>
  </div>

  <form
    (ngSubmit)="updateHierarchyOnsubmit()"
    [formGroup]="hierarchyUpdateForm"
  >
    <div class="modal-body">
      <div class="mb-3">
        <label for="choices-single-default" class="form-label"
          >Admin Level<span class="required-star">*</span></label
        >
        <ng-select
          [items]="adminLevels"
          formControlName="adminLevel"
          placeholder="Select Admin Level"
          id="adminLevel"
          (change)="loadHierarchyUpdate($event)"
          bindLabel="name"
          class="my-dropdown"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
        <p
          *ngIf="
            isSubmitted &&
            hierarchyUpdateForm.get('adminLevel')?.hasError('required')
          "
          class="text-danger m-0"
        >
          Please select an admin level
        </p>
        <span *ngIf="hasErrorFormCheck('administrative_level_id')">
          <app-error
            [errors]="filterErrorsByKey('administrative_level_id')"
          ></app-error>
        </span>
      </div>
      <div class="mb-3" *ngIf="parentVisible">
        <label for="choices-single-default" class="form-label"
          >Parent Name<span
            class="required-star"
            *ngIf="this.hierarchyUpdateForm.get('adminLevel')?.value?.id != 1"
            >*</span
          ></label
        >
        <ng-select
          [items]="filteredHierarchyItems"
          placeholder="Select Parent"
          formControlName="parent"
          id="parent"
          bindLabel="name"
          class="my-dropdown"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
        <p
          *ngIf="
            isSubmitted &&
            hierarchyUpdateForm.get('parent')?.hasError('required')
          "
          class="text-danger m-0"
        >
          Parent is required
        </p>
        <span *ngIf="hasErrorFormCheck('parent_id')">
          <app-error [errors]="filterErrorsByKey('parent_id')"></app-error>
        </span>
      </div>
      <div class="mb-3">
        <label for="name" class="col-form-label name-label"
          >Name<span class="required-star">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="name"
          placeholder="Name"
          id="name"
        />
        <div
          *ngIf="
            isSubmitted && hierarchyUpdateForm.get('name')?.hasError('required')
          "
        >
          <p class="text-danger m-0">Name is required</p>
        </div>
        <span *ngIf="hasErrorFormCheck('name')">
          <app-error [errors]="filterErrorsByKey('name')"></app-error>
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click'); onCloseModel()"
      >
        Close
      </button>
      <button
        type="submit"
        [disabled]="submittedData"
        class="btn btn-primary waves-effect waves-light"
      >
        <i *ngIf="submittedData" class="mdi mdi-spin mdi-dots-circle"></i>
        {{ submittedData == true ? "Please wait..." : "Edit" }}
      </button>
    </div>
  </form>
</ng-template>
